import api from '@/services';

export const namespaced = true;

export const getDefaultState = () => {
  return {
    // Single ticket
    ticket: {
      id: null,
      subject: null,
      location: null,
      country: null,
      review_url: null,
      screenshots_url: null,
      removal_type: null,
      removal_status: null,
      last_activity: null,
      removal_status_group: null,
      successfully_removed_date: null,
      priority: null,
      status: null,
      progress: null,
      requested_date: null,
      last_update: null,
      url_id: null,
      note: null,
      note_last_update: null,
      group_id: null,
      group_name: null,
      timeline: [],
      attempts: null,
    },

    // Summary of tickets by status
    summary: null,

    // List of tickets
    tickets: [],
    total: 0,

    // List of ticket status's
    ticketStatus: null,

    // Available filters
    filters: {
      status: [],
      groups: [],
      removal_type: [],
      publishers: [],
    },
    query: {},

    isLoading: false,
  };
};

export const state = getDefaultState();

export const getters = {
  // Single ticket
  getById: state => (id) => {
    return state.tickets.find(ticket => ticket.id === id);
  },

  /**
   * Ticket's available filters from the API
   */
  getFilters: (state) => {
    return state.filters;
  },
  getFilterStatus: (state) => {
    return state.filters.status;
  },
  getFilterGroups: (state) => {
    return state.filters.groups;
  },
  getFilterRemovalType: (state) => {
    return state.filters.removal_type;
  },
  getFilterPublishers: (state) => {
    return state.filters.publishers;
  },

  /**
   * Ticket human-friendly names
   */
  getPublisherFilterName: state => (id) => {
    return 'Filter: ' + state.filters.removal_type[id].value;
  },
  getStatusGroupName: state => (key) => {
    return state.ticketStatus &&
      state.ticketStatus.removal_status_group_portal[key]
      ? state.ticketStatus.removal_status_group_portal[key]
      : 'unknown';
  },
};

export const mutations = {
  SET_TICKET(state, ticket) {
    state.ticket = ticket;
  },
  SET_TICKETS(state, tickets) {
    state.tickets = tickets;
  },
  SET_TICKETS_TOTAL(state, total) {
    state.total = total;
  },
  SET_TICKET_STATUS(state, status) {
    state.ticketStatus = status;
  },
  SET_SUMMARY(state, summary) {
    state.summary = summary;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  SET_QUERY(state, query) {
    state.query = query;
  },
  SET_LOADING(state, bool) {
    state.isLoading = bool;
  },
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
};

export const actions = {
  async fetchTicket({ commit }, id) {
    commit('SET_LOADING', true);

    try {
      const response = await api.rmvfy.getTicket(id);
      if (response.status === 200) {
        commit('SET_TICKET', response.data);
        return response;
      } else throw response;
    } catch (error) {
      return error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchTicketStatus({ commit }) {
    if (state.ticketStatus) return;
    commit('SET_LOADING', true);

    try {
      const response = await api.rmvfy.getTicketStatus();
      if (response.status === 200) {
        commit('SET_TICKET_STATUS', response.data);
        return response;
      } else throw response;
    } catch (error) {
      return error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchTickets({ commit, state }, query) {
    commit('SET_LOADING', true);

    // Query
    let filter;
    if (query) {
      filter = query;
      commit('SET_QUERY', query);
    } else {
      filter = state.query;
    }

    const params = {
      page: filter.page ? filter.page : 1,
      limit: filter.limit ? filter.limit : 25,
      order: filter.sort ? filter.sort : 'requested_date:desc',
      search: filter.search,
      group: filter.group,
      status: filter.status,
      publisher: filter.publisher,
    };

    try {
      const response = await api.rmvfy.getTickets(params);
      if (response.status === 200) {
        commit('SET_TICKETS', response.data.data);
        commit('SET_SUMMARY', response.data.totals);
        commit('SET_FILTERS', response.data.filters);

        if (state.total !== Number.parseInt(response.data.total)) {
          commit('SET_TICKETS_TOTAL', Number.parseInt(response.data.total));
        }
        return response;
      } else throw response;
    } catch (error) {
      return error.response;
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
