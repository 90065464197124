import { setTags, setUser } from '@sentry/vue';
import mixpanel from 'mixpanel-browser';

import type { TrackingEvent, TrackingName, TrackingServices } from '@/types/track';
import type { User } from '@/types/user';

import { identifyUser as initMixpanelUser } from '@/plugins/mixpanel';
import { useOrganizationStore } from '@/stores/useOrganizationStore';
import { useUserStore } from '@/stores/useUserStore';

import { maskEmail } from '../';

export function tracking<T extends TrackingServices | TrackingServices[]>(
  type: T,
  event: TrackingEvent<T>,
  force = false,
) {
  if (!force) {
    const { isInternalUser } = storeToRefs(useUserStore());
    if (isInternalUser.value === true) return;
  }

  if (Array.isArray(type)) {
    for (const t of type) {
      // HACK: The whole tracking probably needs refactor, for now use this hack
      if (t === 'hj' && event.name !== 'tagRecording') {
        const value: TrackingName[] = event.value ?? [];
        if (event.name !== '' && event.name != null) {
          value.push(event.name);
        }
        tracking('hj', {
          name: 'tagRecording',
          value,
        });
      } else {
      // @ts-expect-error Recursion can ignore this type check
        tracking(t, event, force);
      }
    }
    return;
  }

  // hotjar tagging
  if (type === 'hj' && window.hj) {
    if (event.value && Array.isArray(event.value)) {
      // FIXME:
      // Event name is always 'tagRecording'
      // event value is list of event name
      window.hj(event.name, event.value);
    }
  }

  // gtm event
  if (type === 'gtm' && window.dataLayer) {
    window.dataLayer.push(event);
  }

  // customer io event
  if (type === 'cio' && window._cio) {
    // eslint-disable-next-line ts/strict-boolean-expressions
    if (!event.name) {
      console.error('Customer IO event name is required');
    }

    const { name, ...data } = event;

    window._cio.track(name, data);
  }

  // mixpanel event
  if (type === 'mixpanel') {
    // eslint-disable-next-line ts/strict-boolean-expressions
    if (!event.name) {
      console.error('Mixpanel event name is required');
    }

    const { organizationId: _organizationId } = storeToRefs(useOrganizationStore());
    const organizationId = _organizationId.value || null;

    const { name, ...data } = event;

    mixpanel.track(name as string, {
      ...data,
      ...organizationId ? { organizationId } : {},
    });
  }
}

/**
 *
 * @param {import('@/types/user').User} user
 */
export const getTrackingInitials = (user: User) => {
  const version = import.meta.env.VERSION;
  const isInternalUser = user.isAdmin || user.isDev || user.email.split('@')[1] === 'removify.com';
  const email = maskEmail(user.email);

  if (window.heap) {
    // eslint-disable-next-line no-console
    console.log('heap init');
    window.heap.identify(user.id);
    window.heap.addUserProperties({
      name: user.name,
      email,
    });
  }
  if (window._cio) {
    // eslint-disable-next-line no-console
    console.log('Customer IO init');
    window._cio.identify({
      id: user.id,
    });
  }

  setUser({
    id: user.id,
    email: user.email,
    version,
  });

  setTags({
    'is_internal': isInternalUser,
    'Email suffix': user.email.split('@')[1],
  });

  // mixpanel identify user
  initMixpanelUser(user);

  if (window.clarity) {
    // eslint-disable-next-line no-console
    console.log('Clarity inited');
    window.clarity('set', 'user_id', user.id);
    window.clarity('set', 'is_internal', isInternalUser);
    window.clarity('set', 'email', email);
  }
};

// Customer io accept unix timestamp
export const getCurrentTime = () => {
  return Math.floor(Date.now() / 1000);
};

export * from './customerIo';
