import { createStore } from 'vuex';

import * as amplify from '@/store/modules/amplify/campaign';
import * as amplifyContacts from '@/store/modules/amplify/contacts';
import * as amplifyEmailEditor from '@/store/modules/amplify/emaileditor';
import * as amplifyFeedback from '@/store/modules/amplify/feedback';
import * as auth from '@/store/modules/auth';
import * as cart from '@/store/modules/cart';
import * as faq from '@/store/modules/faq';
import * as message from '@/store/modules/message';
import * as modal from '@/store/modules/modal';
import * as reportsAmplify from '@/store/modules/reports/amplify';
import * as reportsReviews from '@/store/modules/reports/review';
import * as review from '@/store/modules/review';
import * as ticket from '@/store/modules/ticket';
import * as user from '@/store/modules/user';

export const store = createStore({
  modules: {
    auth,
    amplify,
    amplifyContacts,
    amplifyFeedback,
    amplifyEmailEditor,
    user,
    reportsReviews,
    reportsAmplify,
    ticket,
    review,
    faq,
    message,
    modal,
    cart,
  },
  state: {
    reCaptchaSiteKey: '6Le6acIUAAAAAO92FOwVzHYeg06HqUNSuMhDOuvT',
    googleClientId:
      '860205433155-ufe144bihqcfauotcvhgv1oq3anuq7pq.apps.googleusercontent.com',
    toggleMenu: true, // true = expanded, false = collapsed
  },
  mutations: {
    SET_MENU(state, bool) {
      state.toggleMenu = bool;
      localStorage.setItem('_toggle_menu', bool);
    },
    INIT_MENU(state) {
      if (localStorage.getItem('_toggle_menu')) {
        state.toggleMenu = JSON.parse(localStorage.getItem('_toggle_menu'));
      }
    },
  },
  actions: {
    toggleMenu({ commit, state }) {
      localStorage.setItem('_toggle_menu', !state.toggleMenu);
      commit('SET_MENU', !state.toggleMenu);
    },
    logout({ commit }) {
      commit('auth/RESET_STATE');
      commit('user/RESET_STATE');
      commit('amplify/RESET_STATE');
      commit('amplifyContacts/RESET_STATE');
      commit('amplifyFeedback/RESET_STATE');
      commit('amplifyEmailEditor/RESET_STATE');
      commit('faq/RESET_STATE');
      commit('reportsReviews/RESET_STATE');
      commit('reportsAmplify/RESET_STATE');
      commit('ticket/RESET_STATE');
      commit('message/RESET_STATE');
      commit('review/RESET_STATE');
      commit('modal/RESET_STATE');
      commit('cart/RESET_STATE');
    },
    /**
     * This is used for resetting vuex without affecting auth and user
     */
    resetModule({ commit }) {
      commit('amplify/RESET_STATE');
      commit('amplifyContacts/RESET_STATE');
      commit('amplifyFeedback/RESET_STATE');
      commit('amplifyEmailEditor/RESET_STATE');
      commit('faq/RESET_STATE');
      commit('reportsReviews/RESET_STATE');
      commit('reportsAmplify/RESET_STATE');
      commit('ticket/RESET_STATE');
      commit('message/RESET_STATE');
      commit('review/RESET_STATE');
      commit('modal/RESET_STATE');
      commit('cart/RESET_STATE');
    },
  },
});

export default store;
