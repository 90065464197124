import type { RouteRecordRaw } from 'vue-router';

import { Amplify } from '@/router/path/Amplify';
import { Auth } from '@/router/path/Auth';
import { Email } from '@/router/path/Email';
import { External } from '@/router/path/External';
import { FAQ } from '@/router/path/FAQ';
import { Invoice } from '@/router/path/Invoice';
import { Messages } from '@/router/path/Messages';
import { Pages } from '@/router/path/Pages';
import { Removals } from '@/router/path/Removals';
import { Reviews } from '@/router/path/Reviews';
import { System } from '@/router/path/System';
import { User } from '@/router/path/User';

export const routes: RouteRecordRaw[] = [
  ...Removals,
  ...Reviews,
  ...Amplify,
  ...Messages,
  ...FAQ,
  ...System,
  ...Auth,
  ...Pages,
  ...User,
  ...Email,
  ...External,
  ...Invoice,
];
